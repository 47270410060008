import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons'
// import clienteAxios from '../config/Axios';
// import Swal from 'sweetalert2';
// import useExperiences from '../hooks/useExperiences';
// import { StaticImage } from "gatsby-plugin-image"
// import { navigate } from 'gatsby';
// slider
import Slider from "react-slick"
import iconGoogle from "../images/iconGoogle.png"

// import User1 from "../images/user1.jpg"
// import User2 from "../images/user2.jpg"
// import User3 from "../images/user3.jpg"
// import User4 from "../images/user4.jpg"
// import User5 from "../images/user5.jpg"
// import User6 from "../images/user6.jpg"


const Container = styled.section`
    padding-top:6rem;
    max-width:1400px;
    width:95%;
    margin:0 auto;
    @media(max-width:768px){
        max-width:720px;
    }
    @media(max-width:992px){
        max-width:960px;
    }
    @media(max-width:1200px){
        max-width:1140px;
    }
`

const Form = styled.form`
    display:block;
    label{
        display:inline-block;
        padding-bottom:1.5rem;
        width:100%;
        color:#686969;
        font-weight:700;
    }
    label:first-of-type{
        max-width:290px;
        min-width:250px;
        max-height:250px;
        min-height:250px;
        border-radius:1.8rem;
        background-color: #F4F5F7;
        display:flex;
        align-items:center;
        text-align:center;
        margin: auto;
        margin-bottom:3rem;
        cursor: pointer;
        justify-content:center;
        input{
            display:none;
        }
    }
    input, textarea{
        margin-top:1rem;
        display: block;
        width:100%;
        padding: 2rem;
        border: 1px;
        border-radius: 1.8rem;
        color:#686969;
        background-color: #F4F5F7;
        :focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(104, 105, 105,0.1);
            transition: box-shadow .15s ease-in-out;
        }
    }
    button[type=submit]{
        margin-top:1rem;
    display: block;
    width:100%;
    padding: 2rem;
    border: 1px;
    border-radius: 2rem;
        background-color:#FFBD86;
        color: #6E5139;
        text-transform:uppercase;
        font-weight:700;
        border-radius: 4rem;
        -webkit-appearance: none;
        @media(min-width:768px){
            max-width:calc(50% - 1.5rem);
        }
        :hover{
            cursor:pointer;
            background-color:#FFAF6D;
            transition: background-color .15s ease-in-out;
        }
        :focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem #6E5139;
            transition: box-shadow .15s ease-in-out;
            background-color:#FFAF6D;
        }
    }
`

const ImageThumbnail = styled.div`
    border-radius:1.8rem;
    background-color: #FFF;
    display:flex;
    align-items:center;
    text-align:center;
    margin: auto;
    margin-bottom:3rem;
    justify-content:center;
    color:#686969;
    img{
        width: 100%;
        height:auto;
        border-radius:10px;
        max-width:50px;
        margin: auto<;
    }
`

const ShareExperience = () => {
    // const experiences = useExperiences()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

        ]
    }


    // const [formdata, setFormData] = useState({
    //     name: '',
    //     message: ''
    // });
    // const [file, setFile] = useState(false);

    // const { name, message } = formdata;

    // const readFormData = (e) => {
    //     if (e.target.name === 'image') {
    //         console.log(e.target.name)
    //         setFile(e.target.files[0]);
    //         return
    //     }
    //     setFormData({
    //         ...formdata,
    //         [e.target.name]: e.target.value
    //     })
    // }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     if (name === '' || file === '' || message === '') {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error!',
    //             text: 'Todos los campos son obligatorios'
    //         })
    //         return
    //     }

    //     try {
    //         let formData = new FormData();
    //         formData.append("files", file);
    //         const upload = await clienteAxios.post('/upload', formData)

    //         await clienteAxios.post('/experiencias', {
    //             nombre: name,
    //             mensaje: message,
    //             avatar: upload.data[0]._id,
    //             estado: false
    //         })

    //         if (typeof window !== 'undefined') {
    //             navigate('/comentario-enviado');
    //         }
    //     } catch (err) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error!',
    //             text: 'Hubo un error'
    //         })
    //     }

    // }

    let experiences = [
        {
            name: "Jennifer Lagos Araya",
            text: "Me gusto mucho el lugar ,bien central a pasos del portal. Personal muy amable y profesional, todo muy limpio y ordenado. :)",
            start: 5,
            date: "Hace un año",
        },
        {
            name: "Roxana melo",
            text: "Excelente servicio y mucha amabilidad del personal encargado y recepcionista. 100 % recomendable.",
            start: 5,
            date: "Hace un año",
        },
        {
            name: "Javier Sáez",
            text: "Excelente servicio, personas muy responsables y comprometidas con su trabajo, excelente atención, muy satisfecho. Destacar tambien los protocolos de covid19, excelente recibimiento por eso le doy 5 estrellas!!",
            start: 5,
            date: "Hace un año",
        },
        {
            name: "Ely Pobletes",
            text: "Debo decir que me encanto el servicio de la clínica una atención cordial ,lo más importante con las medidas sanitarias correspondiente.Felicidaded súper buen servicio.",
            start: 5,
            date: "Hace un año",
        },
        {
            name: "Francisco Almendras",
            text: "Excelente servicio, buena atención y preocupados por sus pacientes, 100% recomendable",
            start: 5,
            date: "Hace un año",
        },
        {
            name: "Francisca Rojas Alarcón",
            text: "La atención muy buena, 100% recomendable👍🏻👍🏻",
            start: 5,
            date: "Hace un año",
        },

    ]

    return (
        <Container>
            <div>
                <h2
                    css={css`
                    text-align:center;
                    font-size:4rem;
                    text-transform:uppercase;
                    color:#0024B6;
                    @media(min-width:768px){
                        font-size:5rem;
                    }
                    @media(min-width:1200px){
                        font-size:4.5rem;
                        text-align:left;
                    }
                `}>
                    Comentarios de nuestros pacientes
                </h2>
                <p
                    css={css`
                        font-size: 3rem;
                        color: #164688;
                        @media(min-width:1200px){
                        font-size:2.3rem;
                        text-align:left;
                        padding-bottom:5rem;
                    }
                    `}>
                    <a target="_blank" href='https://www.google.com/search?cs=0&hl=es-ES&output=search&q=Centro+Radiologico+Dental+Rayodent&ludocid=13628031868671715606&gsas=1&client=ms-android-samsung-gs-rev1&lsig=AB86z5Vuba91UtT0KmxkvuSyT97Q&kgs=747e10f029f383a9&shndl=-1&shem=lssle&source=sh/x/kp/local/4#lrd=0x9614d3a9ef4e1d23:0xbd207ec7b7e16916,1,,,,'>
                        Reseñas Google
                    </a>
                </p>
                <div>
                    {/* <div
                        css={css`
                                justify-content:center;
                                max-width: 400px;
                                margin: auto;
                                @media (min-width:768px){
                                    max-width:100%;
                                    margin:0;
                                    display:grid;
                                    align-items:start;
                                    grid-template-columns: 1fr 1fr;
                                    column-gap:3rem;
                                }
                                @media (min-width:1200px){
                                    grid-template-columns: 1fr 1fr 1fr;
                                    column-gap:3rem;
                                }
                        `}> */}
                    <div>
                        <Slider {...settings}>
                            {experiences.map(experience => (
                                <div>
                                    <div css={css`border:solid 1px #ccc;border-radius:20px;padding:30px;margin:10px;`}>
                                        <div css={css`display:flex; justify-content:space-between;`}>
                                            <div css={css`display:flex;`}>
                                                <div css={css`margin-right:21px;`}>
                                                    <div css={css`background:#ccc;width:50px;height:50px; border-radius:50%;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;`}>
                                                        {experience.name.charAt(0)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='mb-0' css={css`margin:0; color:#164688; font-weight:700; font-size:120%; text-align:start;`}>
                                                        {experience.name}</p>
                                                    <p className='mb-0' css={css`text-align:start;margin:0;margin-top:-5px;`}>
                                                        <FontAwesomeIcon css={css`color:#FFDB56; font-size:1.5rem;`} icon={faStar} />
                                                        <FontAwesomeIcon css={css`color:#FFDB56; font-size:1.5rem;`} icon={faStar} />
                                                        <FontAwesomeIcon css={css`color:#FFDB56; font-size:1.5rem;`} icon={faStar} />
                                                        <FontAwesomeIcon css={css`color:#FFDB56; font-size:1.5rem;`} icon={faStar} />
                                                        <FontAwesomeIcon css={css`color:#FFDB56; font-size:1.5rem;`} icon={faStar} />
                                                    </p>
                                                    <p className='mb-0' css={css`margin:0; color:#2b2b2b; font-weight:400; font-size:1.5rem; text-align:start;`}>
                                                        18, Diciembre, 2021</p>
                                                </div>
                                            </div>
                                            <div>
                                                <img src={iconGoogle} alt="icon google" />
                                            </div>
                                        </div>
                                        <hr css={css`border:0; height:1px; background:#ccc;`} />
                                        <div>
                                            <p css={css`color:#AFADAD;padding:0 2rem;font-size: 1.7rem;text-align:start; `}>
                                                {experience.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ShareExperience;